import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  en: {
    login: {
      buttons: {
        signIn: 'Sign in',
        forgotPassword: 'Forgot password',
        signUp: 'Sign up',
        google: 'Continue with Google',
        apple: 'Continue with Apple',
      },
      title: 'Login to your account',
      subtitle: 'Welcome back!',
      fields: {
        email: {
          label: 'Email',
          placeholder: 'Enter your email',
        },
        password: {
          label: 'Password',
          placeholder: 'Enter your password',
        },
      },
      or: 'Or',
      keepMeLogedIn: 'Keep me logged in',
      signUpHint: 'Don\'t have a account?',
      error401Title: 'Login failed',
      error401: 'We were unable to log you in.\nPlease check your email address and password.',
      error: 'We apologize, login failed. Please try again.\n\nTo ensure a smoother login experience in the future, we suggest bookmarking {appUrl}. \n\nIf retry fails, contact our support team at {supportEMail}.',
      errorMissingStateParam: 'To ensure a smoother login experience in the future, we suggest bookmarking {appUrl}. \n\nIf retry fails, contact our support team at {supportEMail}.',
      toLoginButton: 'Continue to login',
      errorTitle: 'Login note'
    },
    register: {
      buttons: {
        signIn: 'Sign in',
        signUp: 'Sign up',
        google: 'Sign up with Google',
        apple: 'Sign up with Apple',
      },
      title: 'Create an account',
      subtitle: 'Sign up - no credit card needed.',
      fields: {
        firstName: {
          label: 'First name',
          placeholder: 'Enter your first name',
        },
        lastName: {
          label: 'Last Name',
          placeholder: 'Enter your last name',
        },
        email: {
          label: 'Email',
          placeholder: 'Enter your email',
        },
        password: {
          label: 'Password',
          placeholder: 'Enter a password',
        },
      },
      signInHint: 'Already have an account?',
      error: 'We were unable to create an account with the email and password you entered. Please check your email and password and try again.',
      eulaHint: 'By clicking "Sign up", you agree to ApptiveGrid\'s',
      terms: 'Terms of Service',
      privacy: 'Privacy Policy',
      avv: 'Vendor Data Processing Addendum',
      and: 'and',
      validation: {
        required: 'Required',
        minlength: 'Min 2 characters',
        email:'Please enter a valid email'
      }
    },
    registerSuccess: {
      title: 'Account created!',
      subtitle: 'Please check your email. You will receive an email with a confirmation link.'
    },
    forgotPassword: {
      buttons: {
        resetPassword: 'Reset Password',
        backToLogin: 'Back to login',
      },
      fields: {
        email: {
          label: 'Email',
          placeholder: 'Enter your email',
        },
      },
      title: 'Forgot password',
      subtitle: 'No worries, we\'ll send you instructions.',
      error: 'We were unable to reset your account based on the email you entered. Please check your email and try again.',
    },
    forgotPasswordSuccess: {
      buttons: {
        resendMail: 'Resend email',
      },
      title: 'Check your email',
      subtitle: 'We sent a password reset link.',
      didNotGetMail: 'Didn\'t receive the email?'
    },
    resetPassword: {
      buttons: {
        resetPassword: 'Set password',
      },
      fields: {
        password: {
          label: 'Password',
          placeholder: 'Enter your new password',
        },
      },
      title: 'Set Password',
      subtitle: 'Choose a new password',
      error: 'We could not reset the password. Please check the password and try again.'

    },
    resetPasswordSuccess: {
      buttons: {
        signIn: 'Login',
      },
      title: 'Updated your Password',
      subtitle: 'Your are ready to log in.',
    },
    accoutConfirmation: {
      buttons: {
        login: 'Open ApptiveGrid'
      },
      title: 'Welcome to ApptiveGrid',
      subtitle: 'Your account is ready.',
    },
    ad: {
      quote1: {
          text: 'ApptiveGrid is fun to work with, simple, intuitive and customizable.',
          name: 'Ina Dosch',
          jobTitle: 'Academy of Public Health Germany (OEGW)',
      }
    }
  },
  de: {
    login: {
      buttons: {
        signIn: 'Einloggen',
        forgotPassword: 'Passwort vergessen',
        signUp: 'Registrieren',
        google: 'Mit Google anmelden',
        apple: 'Mit Apple anmelden',
      },
      title: 'Einloggen',
      subtitle: 'Willkommen zurück!',
      eulaHint: 'Indem du „Anmelden" auswählst, erklärst du dein Einverständnis mit ApptiveGrids',
      fields: {
        email: {
          label: 'E-Mail',
          placeholder: 'Gib deine E-Mail ein',
        },
        password: {
          label: 'Passwort',
          placeholder: 'Gib dein Passwort ein',
        },
      },
      or: 'Oder',
      keepMeLogedIn: 'Angemeldet bleiben',
      signUpHint: 'Du hast noch keinen Account?',
      error401Title: 'Anmeldung fehlgeschlagen',
      error401: 'Wir konnten Sie leider nicht anmelden.\nBitte überprüfen Sie Ihre E-Mail-Adresse und Ihr Passwort.',
      error: 'Es tut uns leid, die Anmeldung ist fehlgeschlagen. Bitte versuchen Sie es erneut.\n\nUm die Anmeldung in Zukunft reibungsloser zu gestalten, empfehlen wir Ihnen, {appUrl} als Lesezeichen zu speichern. \n\nWenn der erneute Versuch fehlschlägt, kontaktieren Sie unser Support-Team unter {supportEMail}.',
      errorMissingStateParam: 'Um die Anmeldung in Zukunft reibungsloser zu gestalten, empfehlen wir Ihnen, {appUrl} als Lesezeichen zu speichern. \n\nWenn der erneute Versuch fehlschlägt, kontaktieren Sie unser Support-Team unter {supportEMail}.',
      toLoginButton: 'Weiter zum Login',
      errorTitle: 'Hinweis zum Login',
    },
    register: {
      buttons: {
        signIn: 'Einloggen',
        signUp: 'Account erstellen',
        google: 'Weiter mit Google',
        apple: 'Weiter mit Apple ',
      },
      title: 'Account erstellen',
      subtitle: 'Keine Kreditkarte notwendig.',
      fields: {
        firstName: {
          label: 'Vorname',
          placeholder: 'Gib deinen Vornamen ein',
        },
        lastName: {
          label: 'Nachname',
          placeholder: 'Gib deinen Nachnamen ein',
        },
        email: {
          label: 'E-Mail Adresse',
          placeholder: 'Gib deinen berufliche E-Mail ein',
        },
        password: {
          label: 'Passwort',
          placeholder: 'Gib ein Passwort ein',
        },
      },
      signInHint: 'Du hast bereits einen Account?',
      error: 'Wir konnten mit der von Ihnen eingegebenen E-Mail und dem Passwort kein Konto erstellen. Bitte überprüfen Sie Ihre E-Mail und Ihr Passwort und versuchen Sie es erneut.',
      eulaHint: 'Indem du „Account erstellen“ oder "Weiter mit" auswählst, erklärst du dein Einverständnis mit ApptiveGrids',
      terms: 'Geschäftsbedingungen',
      privacy: 'Datenschutzbestimmungen',
      avv: 'Auftragsverarbeitungsvertrag',
      and: 'und',
      validation: {
        required: 'Pflichtfeld',
        minlength: 'Mehr als 2 Buchstaben',
        email:'Bitte geben Sie ein gültige E-Mail Adresse ein'
      }
    },
    registerSuccess: {
      title: 'Account wurde erstellt!',
      subtitle: 'Bitte prüf dein E-Mail Postfach. Wir haben dir eine E-Mail mit einem Link zur Bestätigung gesendet.'
    },
    forgotPassword: {
      buttons: {
        resetPassword: 'Passwort zurücksetzen',
        backToLogin: 'Zum Login',
      },
      fields: {
        email: {
          label: 'E-Mail',
          placeholder: 'Gib deine E-Mail Adresse ein',
        },
      },
      title: 'Passwort vergessen',
      subtitle: 'Keine Sorge, wir senden dir ein E-Mail mit weiteren Schritten zu.',
      error: 'Wir konnten dein Account anhand der E-Mail nciht zurückzusetzen. Bitte überprüfe deine E-Mail und versuchen Sie es erneut.',
    },
    forgotPasswordSuccess: {
      buttons: {
        resendMail: 'Erneut senden',
      },
      title: 'Bitte prüfe dein Postfach.',
      subtitle: 'Wir haben dir eine E-Mail gesendet.',
      didNotGetMail: 'Du hast keine E-Mail erhalten?'
    },
    resetPassword: {
      buttons: {
        resetPassword: 'Passwort übernehmen',
      },
      fields: {
        password: {
          label: 'Passwort',
          placeholder: 'Gib ein Passwort ein',
        },
      },
      title: 'Passwort zurücksetzen',
      subtitle: 'Wähle dein neues Passwort.',
      error: 'Wir konnten das Passwort nicht zurücksetzen. Bitte überprüfen Sie das Passwort und versuchen Sie es erneut.'
    },
    resetPasswordSuccess: {
      buttons: {
        signIn: 'Zur Anmeldung',
      },
      title: 'Dein Passwort wurde übernommen',
      subtitle: 'Du kannst dich jetzt anmelden.',
    },
    accoutConfirmation: {
      buttons: {
        login: 'Weiter zum Login'
      },
      title: 'Willkommen bei ApptiveGrid!',
      subtitle: 'Dein Account ist nun breit.',
    },
    ad: {
      quote1: {
          text: 'Mit ApptiveGrid zu arbeiten macht Spaß, es ist einfach, intuitiv und anpassbar.',
          name: 'Ina Dosch',
          jobTitle: 'Akademie für Öffentliches Gesundheitswesen'
      }
    }
  }
}

// Create VueI18n instance with options
export default new VueI18n({
  locale: navigator.language?.includes('de') ? 'de' : 'en', // set locale
  messages // set locale messages
})
