import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: colors.blue.darken3,
        secondary: colors.blue,
        accent: colors.grey.darken2,
        error: colors.red.accent3,
        destructive: colors.red.accent1
      }
    }
  }
}

export default new Vuetify(opts)
