<template lang="html">
  <div class="ma-3">
    

      <v-dialog
      v-model="error.show"
      persistent
      max-width="450"
        >
          <v-card>
            <v-card-title class="headline text-center red--text">
              {{error.title}}
            </v-card-title>
            <v-card-text>
              {{error.message}}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="error.action">
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      <h1>{{$t('login.title')}}</h1>
      <div class="mb-2 text-h6">{{$t('login.subtitle')}}</div>
      <span class="mr-2">{{$t('login.signUpHint')}} </span><router-link to="/register" class="mt-0" text>{{$t('login.buttons.signUp')}}</router-link>


      <v-btn @click="loginGoogle" elevation="2" rounded block large class="button mb-4 mt-4" color="white" depressed><img class="ml-4 mr-4" height="24px" src="google-logo.svg" />{{$t('login.buttons.google')}}</v-btn>
      <v-btn @click="loginApple" elevation="2" rounded block large class="button mb-4" color="white" depressed><img src="apple-logo.svg" />{{$t('login.buttons.apple')}}</v-btn>
    
      <v-row class="ma-6" align="center">
        <v-divider class="mr-3"/>
        <span>{{$t('login.or')}}</span>
        <v-divider class="ml-3"/>
      </v-row>
      <div align="start" class="ml-1 mb-2 mt-n2 bold accent--text">{{$t('login.fields.email.label')}}</div>
      <form action="">    
      <v-text-field
        v-model="email"
        :placeholder="`${$t('login.fields.email.placeholder')}`"
        solo
        flat
        background-color="white"
        class="textField"
        type="email"
        autocomplete="username"
        @keyup.enter="$refs.password.focus()"
        @focus="showHiddenFields = true"

      />


      <div  align="start" class="ml-1 mb-2 mt-n2 bold accent--text">{{$t('login.fields.password.label')}}</div>
      <v-text-field
        v-model="password"
        ref="password"
        :placeholder="`${$t('login.fields.password.placeholder')}`"
        solo
        flat
        password
        background-color="white"
        class="textField"
        :type="showPassword ? 'text' : 'password'"
        autocomplete="current-password"
        @click:append="showPassword = !showPassword"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @keyup.enter="login"
        @focus="showHiddenFields = true"

      />
    </form>
    <v-expand-transition>
      <div v-show="showHiddenFields">
      <div class="d-flex mb-2 justify-end">
        <!-- <v-checkbox dense :label="$t('login.keepMeLogedIn')"></v-checkbox>  -->
        <router-link to="/forgotPassword" class="mt-2 bold" text>{{$t('login.buttons.forgotPassword')}}</router-link>
      </div>
      <v-btn :loading="loading" @click="login" rounded elevation="2" block large class="button bold mb-4" color="primary" depressed>{{$t('login.buttons.signIn')}}</v-btn>
    </div>
  </v-expand-transition>
  <div class="mb-4 mt-2 accent--text terms">
        <span >{{$t('login.eulaHint')}} </span>
        <a class="terms" href="https://www.apptivegrid.de/datenschutz" target="_blank">{{$t('register.terms')}}</a>,
        <a class="terms" href="https://www.apptivegrid.de/datenschutz#datenschutz" target="_blank">{{$t('register.privacy')}}</a> {{$t('register.and')}}
        <a class="terms" href="https://www.apptivegrid.de/datenschutz#avv" target="_blank">{{$t('register.avv')}}</a>.
      </div>
  </div>
</template>

<script>
// import { sha256 } from 'js-sha256'
import { b64EncodeUnicode } from '@/utils/base64.js'

const errorTemplate = (show = false, title, message, action = () => {} ) => {
    return {
        'show': show,
        'title': title,
        'message': message,
        'action': action
    }}
export default {
  props: ['stateParam'],
  data() {
    return {
      email: null,
      password: null,
      showPassword: false,
      loading: false,
      error: errorTemplate,
      showHiddenFields: false
    }
  },
  methods: {
    async login() {
      this.loading = true
      this.error = errorTemplate
      let response = await fetch(window.location.protocol + "//" + window.location.host + "/auth/apptivegrid/login?" + new URLSearchParams({
        state: this.stateParam
      }), {
        headers: {
          Authorization: 'Basic ' + b64EncodeUnicode(this.email + ":" + this.password)
        }
      })
      if (response.status == 201) {
        // successfull login
        this.loginSuccess(response)
      }
      else if (response.status == 401){
        // wrong credentals
        this.error = errorTemplate(true, this.$t('login.error401Title'), this.$t('login.error401') , this.hideError)
        if(this.email == 'testError') {
          this.logRespose(response)
        }
      }
      else {
        // non 401 error indicates that something in the auth process went wrong.
        // show a popup from which the user can trigger hole auth flow again by redirect to app.apptivegrid.de
        this.$emit('unknowError')
        // log error
        this.logRespose(response)
      }
      this.loading = false
    },
    async logRespose(response) {
      let responseText = await response.text()
      const message = `An error (${response.status}) call get auth/apptivegrid/login: ${responseText}`
      this.$apptiveErrorReporter.captureException(new Error(message), { 'apptive-email' : this.email })
    },
    hideError(){
      this.error = errorTemplate
    },
    loginGoogle() {
      //http://app.apptivegrid.de/auth/apptivegrid/google/login?state=21f10050-a4a9-0d00-a4ea-f4a307897eb0
      window.location.href = `${window.location.protocol}//${window.location.host}/auth/apptivegrid/google/login?state=${this.stateParam}`
    },
    loginApple() {
      window.location.href = `${window.location.protocol}//${window.location.host}/auth/apptivegrid/apple/login?state=${this.stateParam}`
    },
    loginSuccess(response) {
      let location = response.headers.get('location')
      window.location.href = location
    }
  },
}
</script>
<style lang="css" scoped>
.textField >>> .v-input__slot {
  border: 1px solid #D0D5DD !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.terms {
  font-weight: normal;
}
</style>
