<template lang="html">
  <div class="ma-3">
    <v-snackbar
      :timeout="5000"
      :value="error"
      color="red darken-1"
      absolute
      rounded
      top
      >
      {{$t('resetPassword.error')}}
    </v-snackbar>
  <h1>{{$t('resetPassword.title')}}</h1>
  <div class="mb-6 accent--text">{{$t('resetPassword.subtitle')}}</div>

  <div align="start" class="ml-1 mb-2 bold accent--text">{{$t('resetPassword.fields.password.label')}}</div>
  <v-text-field
    v-model="password"
    :placeholder="`${$t('resetPassword.fields.password.placeholder')}`"
    solo
    flat
    elevation="1"
    background-color="white"
    class="textField"
    :type="showPassword ? 'text' : 'password'"
    autocomplete="new-password"
    @click:append="showPassword = !showPassword"
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
  />

  <v-btn @click="resetPassword" rounded elevation="2" block large class="button bold mb-3" color="primary" depressed>{{$t('resetPassword.buttons.resetPassword')}}</v-btn>

</div>
</template>

<script>
export default {
  data() {
    return {
      password: null,
      showPassword: false,
      loading: false,
      error: false
    }
  },
  methods: {
    async resetPassword() {
      this.loading = true
      this.error = false
      const urlParams = new URLSearchParams(window.location.search)
      const accountId = urlParams.get('accountId')
      const securityToken = urlParams.get('securityToken')
      const response = await fetch(window.location.protocol + "//" + window.location.host + `/auth/apptivegrid/resetPassword/${accountId}/${securityToken}`, {
        method: 'PUT',
        body: JSON.stringify({ "newPassword": this.password }),
      })
      if (response.status == 200) {
        this.$router.push( {name: 'ResetPasswordSuccess'})
      } else {
        this.error = true
        if(!response.ok) {
          // log error
          let responseText = await response.text()
          const message = `An error (${response.status}) call get auth/apptivegrid/resetPassword: ${responseText}`
          this.$apptiveErrorReporter.captureException(new Error(message))
        }
      }
      this.loading = false
    }
  }
}
</script>

<style lang="css" scoped>
.textField >>> .v-input__slot {
  border: 1px solid #D0D5DD !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
</style>
