export default {
    'localhost': {
        'form': '/api/a/63c177466558e302ecae4b1f/63c6af169fd44b0497aed771'
    },
    'alpha.apptivegrid.de': {
        'form': '/api/a/63c177466558e302ecae4b1f/63c6af169fd44b0497aed771'
    },
    'beta.apptivegrid.de': {
        'form': '/api/a/63c6abea5c22b758a6aed7fa/63c6acae9074eddf3eaed842'
    },
    'app.apptivegrid.de': {
        'form': '/api/a/63c11a836b51209950adeb8f/63c6aee778c93f585caed55e'
    }
}