<template lang="html">
  <div class="ma-3">
    <v-snackbar
      :timeout="5000"
      :value="error"
      color="red darken-1"
      absolute
      rounded
      top
      >
      {{$t('register.error')}}
    </v-snackbar>
    <h1>{{$t('register.title')}}</h1>
    <div class="mb-6 accent--text ">{{$t('register.subtitle')}}</div>

    <v-btn @click="registerGoogle" elevation="2" rounded block large class="button mb-4" color="white" depressed><img class="ml-4  mr-4" height="24px" src="google-logo.svg" />{{$t('register.buttons.google')}}</v-btn>
    <v-btn @click="registerApple" elevation="2" rounded block large class="button mb-4" color="white" depressed><img src="apple-logo.svg" />{{$t('register.buttons.apple')}}</v-btn>

    <v-row class="ma-6 mb-5" align="center">
      <v-divider class="mr-3"/>
      <span>{{$t('login.or')}}</span>
      <v-divider class="ml-3"/>
    </v-row>
    <v-form ref="form">
    <div align="start" class="ml-1 mb-1 mt-n2 bold accent--text">{{$t('register.fields.email.label')}}</div>
    <v-text-field
      v-model="email"
      :placeholder="`${$t('register.fields.email.placeholder')}`"
      autocomplete="username"
      solo
      flat
      background-color="white"
      class="textField"
      @focus="showHiddenFields = true"
      @keyup.enter="$refs.firstName.focus()"
      @blur="hideErrors.email = false"
      :rules="hideErrors.email ? [] : [rules.required, rules.email]"
      ref="email"
    />

    <v-expand-transition>
    <div v-show="showHiddenFields">


      <div align="start" class="ml-1 mb-1 bold accent--text">{{$t('register.fields.firstName.label')}}</div>
      <v-text-field
        v-model="firstName"
        autocomplete="given-name"
        :placeholder="`${$t('register.fields.firstName.placeholder')}`"
        solo
        flat
        background-color="white"
        class="textField"
        @keyup.enter="$refs.lastName.focus()"
        ref="firstName"
        @blur="hideErrors.firstName = false"
        :rules="hideErrors.firstName ? [] : [rules.required, rules.counter]"
      />

      <div align="start" class="ml-1 mb-1 mt-n2 bold accent--text">{{$t('register.fields.lastName.label')}}</div>
      <v-text-field
        v-model="lastName"
        autocomplete="family-name"
        :placeholder="`${$t('register.fields.lastName.placeholder')}`"
        solo
        flat
        background-color="white"
        class="textField"
        @keyup.enter="$refs.password.focus()"
        ref="lastName"
        @blur="hideErrors.lastName = false"
        :rules="hideErrors.lastName ? [] : [rules.required, rules.counter]"
      />

      <div align="start" class="ml-1 mb-1 mt-n2 bold accent--text">{{$t('register.fields.password.label')}}</div>
      <v-text-field
        v-model="password"
        :placeholder="`${$t('register.fields.password.placeholder')}`"
        solo
        flat
        elevation="1"
        background-color="white"
        class="textField"
        :type="showPassword ? 'text' : 'password'"
        autocomplete="new-password"
        @click:append="showPassword = !showPassword"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        ref="password"
        @keyup.enter="register"
        @blur="hideErrors.password = false"
        :rules="hideErrors.password ? [] : [rules.required, rules.counter]"

      />
      </div>

      </v-expand-transition>
    </v-form>
      <div class="mb-4">
        <span >{{$t('register.eulaHint')}} </span>
        <a class="terms" href="https://www.apptivegrid.de/datenschutz" target="_blank">{{$t('register.terms')}}</a>,
        <a class="terms" href="https://www.apptivegrid.de/datenschutz#datenschutz" target="_blank">{{$t('register.privacy')}}</a> {{$t('register.and')}}
        <a class="terms" href="https://www.apptivegrid.de/datenschutz#avv" target="_blank">{{$t('register.avv')}}</a>.
      </div>
          <v-btn :loading="loading" @click="register" rounded elevation="2" block large class="button bold mb-4" color="primary" depressed>{{$t('register.buttons.signUp')}}</v-btn>
          <span class="mr-2">{{$t('register.signInHint')}}</span> <BackToLogin :title="$t('register.buttons.signIn')" />
        </div>
</template>

<script>
import BackToLogin from '@/components/BackToLogin.vue'

export default {
  props: ['stateParam'],
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      showPassword: false,
      loading: false,
      error: false,
      showHiddenFields: false,
      hideErrors: {
        email: true
      },
      rules: {
          required: value => !!value || this.$t('register.validation.required'),
          counter: value => value?.length >= 3 || this.$t('register.validation.minlength'),
          email: value => {
            const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            return pattern.test(value) || this.$t('register.validation.email')
          },
      }
    }
  },

  computed: {
  },
  methods:{
    async register() {
      if(!this.$refs.form.validate()) return
      this.loading = true
      this.error = false

      const data = {
        "firstName": this.firstName,
        "lastName": this.lastName,
        "email": this.email,
        "password": this.password
      }
      const response = await fetch(`${window.location.origin}/auth/apptivegrid/accounts?state=${this.stateParam}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      if (response.status == 201) {
        this.$router.push( {name: 'RegistrationSuccessfull'})
      } else {
        this.error = true
        if(!response.ok) {
          // log error
          let responseText = await response.text()
          const message = `An error (${response.status}) call get auth/apptivegrid/accounts: ${responseText}`
          this.$apptiveErrorReporter.captureException(new Error(message), { 'apptive-email' : this.email })
        }
      }
      this.loading = false
    },
    registerGoogle() {
      let location = `${window.location.origin}/auth/apptivegrid/google/login?state=${this.stateParam}`
      window.location.href = location
    },
    registerApple() {
      let location = `${window.location.origin}/auth/apptivegrid/apple/login?state=${this.stateParam}`
      window.location.href = location
    }
  },
  components: {
    BackToLogin
  }
}
</script>

<style lang="css" scoped>
.terms {
  font-weight: normal;
}
.textField >>> .v-input__slot {
  border: 1px solid #D0D5DD !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

</style>
