<template lang="html">
  <div class="ma-3">
    <v-snackbar
      :timeout="5000"
      :value="error"
      color="red darken-1"
      absolute
      rounded
      top
      >
      {{$t('forgotPassword.error')}}
    </v-snackbar>
  <h1>{{$t('forgotPassword.title')}}</h1>
  <div class="mb-6 accent--text">{{$t('forgotPassword.subtitle')}}</div>

  <div align="start" class="ml-1 mb-2 bold accent--text">{{$t('forgotPassword.fields.email.label')}}</div>
  <v-text-field
    v-model="email"
    :placeholder="`${$t('forgotPassword.fields.email.placeholder')}`"
    solo
    flat
    background-color="white"
    class="textField"
  />

  <v-btn
    @click="forgotPassword"
    rounded
    elevation="2"
    block
    large
    class="button bold mb-3"
    color="primary"
    depressed>
      {{$t('forgotPassword.buttons.resetPassword')}}
    </v-btn>
    <BackToLogin
      :title="$t('forgotPassword.buttons.backToLogin')">
        <v-icon color="primary">mdi-arrow-left</v-icon>
    </BackToLogin>
</div>
</template>

<script>
import BackToLogin from '@/components/BackToLogin.vue'

export default {
  data() {
    return {
      email: null,
      loading: false,
      error: false
    }
  },
  methods: {
    async forgotPassword () {
      this.loading = true
      this.error = false
      const response = await fetch(window.location.protocol + "//" + window.location.host + "/auth/apptivegrid/forgotPassword?" + new URLSearchParams({
        client_id: 'web'
      }), {
        method: 'POST',
        body: JSON.stringify({ "email": this.email }),
      })
      if (response.status == 200) {
        this.$router.push( {name: 'ForgotPasswordSuccess'})
      } else {
        this.error = true
        if(!response.ok) {
          // log error
          let responseText = await response.text()
          const message = `An error (${response.status}) call get auth/apptivegrid/forgotPassword: ${responseText}`
          this.$apptiveErrorReporter.captureException(new Error(message), { 'apptive-email' : this.email })

        }
      }
      this.loading = false
    }
  },
  components: {
    BackToLogin
  }
}
</script>

<style lang="css" scoped>
.textField >>> .v-input__slot {
  border: 1px solid #D0D5DD !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
</style>
