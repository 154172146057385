class AEREvent  {
  constructor(error, properties = {}, target) {
    this.error = error
    this.properties = properties
    this.target = target
  }
}

export const logTarget = {
  CONSOLE: 'console',
  CLOUD: 'cloud',
  IGNORE: 'ignore'
}

class AERQueue {

  constructor() {
    this.items = []
  }

  enqueue(item) {
    this.items.push(item)
  }

  dequeue() {
    if (this.isEmpty()) {
      return null
    }
    return this.items.shift()
  }

  isEmpty() {
    return this.items.length === 0
  }
}

class ApptiveErrorReporter {

  constructor() {
    this.form = null
    this.formLink = null
    this.appVersion = null
    this.app = null
    this.errorSent = false
    this.target = logTarget.CLOUD
    this.eventQueue = new AERQueue()
    this.initialized = false
    window.onerror = (error) => {
      this.captureException(error)
    }
    // catch errors in async functions
    window.addEventListener( 'unhandledrejection', (error) => {
      this.captureException(error)
    })
  }

  init({form, app, version = '1.0', target = logTarget.CLOUD , beforeSend = ( event ) => { return event }} ) {
    this.formLink = form
    this.app = app
    this.appVersion = version
    this.target = target
    this.beforeSend = beforeSend
    this.initialized = true
    if(target === logTarget.CONSOLE) {
      console.log('ApptiveErrorReporter logging to console only')
    }
    this.processEventQueue()
  }

  captureException(error, properties) {
    let event = new AEREvent(error, properties, this.target)
    if ( this.initialized ) {
      this._sendErrorToServer(event)
    }
    else {
      this.eventQueue.enqueue(event)
    }
  }

  async _getForm(link) {
    if(this.form) return this.form
    const response = await fetch(link)
    this.form = response.json()
    return this.form
  }

  processEventQueue() {
    while (!this.eventQueue.isEmpty()) {
      const event = this.eventQueue.dequeue()
      this._sendErrorToServer(event)
    }
  }

  async _sendErrorToServer(aEvent) {
    const event = this.beforeSend(aEvent)
    if(event.target === logTarget.CONSOLE) {
      console.log(event.error)
      console.log(event)
      return
    }
    if(this.errorSent || event.target !== logTarget.CLOUD) return // to start with minial impact on backend, we start with sending one error per session
      const form = await this._getForm(this.formLink)
      const data = this._constructPayload(event, form)
      const options = {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
              'Content-Type': 'application/json'
          }
      }
      await fetch(form._links.submit.href, options)
      this.errorSent = true
  }
  _constructPayload(event, form) {
    // get form field id
    let payload = {}
    let error = event.error
    form.fields.forEach( field => {
      switch (field.key) {
        case 'appName':
          payload[field.id] = this.app || null
          break
        case 'message':
          payload[field.id] = error.message || null
          break
        case 'name':
          payload[field.id] = error.name || null
          break
        case 'stack':
          payload[field.id] = error.stack || null
          break
        case 'appVersion':
          payload[field.id] = this.appVersion || null
          break
        case 'userAgent':
          payload[field.id] = navigator.userAgent || null
          break
        case 'userLanguage':
          payload[field.id] = window.navigator.userLanguage || window.navigator.language || null
          break
        default:
          payload[field.id] = null
      }
      // fill properties 
      if(field.key in event.properties) {
        payload[field.id] = event.properties[field.key] || null
      }
    })
    return payload
  }
}

const apptiveErrorReporter = new ApptiveErrorReporter()
export default apptiveErrorReporter

