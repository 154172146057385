<template lang="html">
  <div class="ma-3">
  <v-img src=""></v-img>
  <h1>{{$t('forgotPasswordSuccess.title')}}</h1>
  <div class="mb-6 accent--text">{{$t('forgotPasswordSuccess.subtitle')}}</div>

  <div class="mb-5">
    <span class="mr-2">{{$t('forgotPasswordSuccess.didNotGetMail')}} </span><router-link to="/forgotPassword" class="mt-0 bold" text>{{$t('forgotPasswordSuccess.buttons.resendMail')}}</router-link>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style lang="css" scoped>
</style>
