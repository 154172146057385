<template lang="html">
  <div class="full-height advertisement">
      <v-row class="full-height">
        <v-col class="quote" align-self="center" align="center">
          <h2>{{$t('ad.quote1.text')}}</h2>
          <div class="font-weight-bold pt-6">{{$t('ad.quote1.name')}}</div>
          <div class="mb-5">{{$t('ad.quote1.jobTitle')}}</div>
          <v-rating
            color="#FEC84B"
            hover
            length="5"
            readonly
            size="22"
            :value="5"
            ></v-rating>
        </v-col>
      </v-row>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
.quote {
  margin: 40px;
}
.full-height {
  min-height: 100vh;
}
.advertisement {
  background-color: #F2F4F7;
}
</style>
