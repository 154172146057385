import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import i18n from './plugins/i18n' // path to vuetify export
import apptiveErrorReporter from '@/plugins/apptiveErrorReporter.js'

Vue.config.productionTip = false

Vue.prototype.$apptiveErrorReporter = apptiveErrorReporter
Vue.config.errorHandler = (error) => apptiveErrorReporter.captureException(error)

new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
