<template lang="html">
  <span>
  <slot></slot>
  <a class="ml-1" @click="toLogin">{{title}}</a>
  </span>
</template>

<script>
export default {
  props: ['title'],
  methods: {
    toLogin() {
      // to kick off a proper authorise process, we redirect to the app
      const urlParams = new URLSearchParams(window.location.search)
      urlParams.delete('state')
      window.location.href = `/?${urlParams.toString()}`
    }
  }
}
</script>

<style lang="css" scoped>
</style>
