<template lang="html">
  <div class="ma-3">
    <h1>{{$t('registerSuccess.title')}}</h1>
    <div class="mb-6 accent--text">
      {{$t('registerSuccess.subtitle')}}
    </div>
  </div>
</template>

<script>
export default {
  methods: {
  }
}
</script>

<style lang="css" scoped>
</style>
