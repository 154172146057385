import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginUser from '@/components/LoginUser.vue'
import RegisterUser from '@/components/RegisterUser.vue'
import RegisterUserSuccessfull from '@/components/RegisterUserSuccessfull.vue'
import ForgotPassword from '@/components/ForgotPassword.vue'
import ForgotPasswordSuccess from '@/components/ForgotPasswordSuccess.vue'
import AccountConfirmation from '@/components/AccountConfirmation.vue'
import ResetPassword from '@/components/ResetPassword.vue'
import ResetPasswordSuccess from '@/components/ResetPasswordSuccess.vue'
import AdComponent from '@/components/AdComponent.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Login',
    components: { MainContent: LoginUser, AdContent: AdComponent },
    meta: { requiresState: true }
  },
  {
    path: '/login',
    name: 'Login',
    components: { MainContent: LoginUser, AdContent: AdComponent },
    meta: { requiresState: true }
  },
  {
    path: '/register',
    name: 'Register',
    components: { MainContent: RegisterUser, AdContent: AdComponent },
    meta: { requiresState: true }
  },
  {
    path: '/register-successfull',
    name: 'RegistrationSuccessfull',
    components: { MainContent: RegisterUserSuccessfull, AdContent: AdComponent },
    meta: { requiresState: false }
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    components: { MainContent: ForgotPassword, AdContent: AdComponent },
    meta: { requiresState: false }
  },
  {
    path: '/forgotPassword-successfull',
    name: 'ForgotPasswordSuccess',
    components: { MainContent: ForgotPasswordSuccess, AdContent: AdComponent },
    meta: { requiresState: false }
  },
  {
    path: '/confirm',
    name: 'AccountConfirmation',
    components: { MainContent: AccountConfirmation, AdContent: AdComponent },
    meta: { requiresState: false }
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    components: { MainContent: ResetPassword, AdContent: AdComponent },
    meta: { requiresState: false }
  },
  {
    path: '/resetpassword-successfull',
    name: 'ResetPasswordSuccess',
    components: { MainContent: ResetPasswordSuccess, AdContent: AdComponent },
    meta: { requiresState: false }
  },
]

const router = new VueRouter({
  routes // short for `routes: routes`
})


export default router
