<template>
  <v-app>
    <v-dialog
        v-model="errorDialog.show"
        scrollable  
        persistent :overlay="true"
        max-width="500px"
        transition="dialog-transition"
      >
      <v-card>
        <v-card-title class="text-h5">
          {{$t('login.errorTitle')}}
        </v-card-title>

        <v-card-text class="subtitle-1 text-pre-line">
          {{errorDialog.message}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.native="errorDialog.action">
            {{$t('login.toLoginButton')}}
          </v-btn>       
        </v-card-actions>
        
      </v-card>
 

      </v-dialog>
    <v-row no-gutter class=" white animation ma-0 pa-0">
      <v-col align-self="center" align="center" class="ma-0 pa-0" >
          <div class="d-flex flex-column full-height  pa-2">
            <div class=" d-flex justify-start hidden-sm-and-down">
              <v-img class="ma-4 hidden-sm-and-down" max-width="160px" src="logo.svg"></v-img>
            </div>
            <div class="d-flex justify-center align-center flex-grow-1">
              <router-view @unknowError="showErrorDialog" :stateParam="stateParam" class="login animation" name="MainContent"></router-view>
            </div>
            <div class=" d-flex justify-start" >
              <v-btn :href="mail" color="accent" class="subtitle-1" text><v-icon class="mr-2">mdi-lifebuoy</v-icon>support@apptivegrid.de</v-btn>
            </div>
          </div>
      </v-col>
      <v-col align-self="center" align="center" class="hidden-sm-and-down ma-0 pa-0">
        <router-view  class="hidden-sm-and-down" name="AdContent"></router-view>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import config from '@/constants/config.js'
import { logTarget } from '@/plugins/apptiveErrorReporter.js'
const errorTemplate = (show = false, message, action = () => {} ) => {
    return {
        'show': show,
        'message': message,
        'action': action
    }}
export default {
  name: 'App',
  data() {
    return {
      errorDialog: errorTemplate,
      stateParam: null
    }
  },
  mounted() {
    const form = config[window.location.hostname]?.form
    if (form != null) {
      let target = (process.env.NODE_ENV === 'development') ? logTarget.CONSOLE : logTarget.CLOUD
      this.$apptiveErrorReporter.init({ app: 'ApptiveGrid-Login', form: form , target: target,  beforeSend: this.beforeSendError})
    }

    if(!this.hasStateParam && this.$route.meta.requiresState) {
      this.showErrorDialog()
      return
    }
    // read state param
    this.stateParam = this.getStateParam()
    // delete state form url to prevent user from bookmarking a state 
    this.deleteStateParam()
    // show timeout dialog once the state param and the auth challange might be outdated
    setTimeout(() => {
      if( this.errorDialog.show != true ) {
        this.showErrorDialog()
      }
    }, 30 * 60 * 1000)
  },
  methods: {
    deleteStateParam() {
      // Create a new URLSearchParams object
      const urlParams = new URLSearchParams(window.location.search)

      // Remove the parameter you want
      urlParams.delete('state')

      // Get the modified URL
      const modifiedUrl = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`

      // Update the URL without reloading the page
      history.pushState(null, '', modifiedUrl)
    },
    getStateParam() {
      const urlParams = new URLSearchParams(window.location.search)
      const state = urlParams.get('state')
      return state
    },
    showErrorDialog() {
      this.errorDialog = errorTemplate(true, this.$t('login.errorMissingStateParam', { supportEMail: 'support@apptivegrid.de' , appUrl:  window.location.origin } ) , this.triggerLoginProcess)
    },
    beforeSendError(event) {
      // log additional infos
      event.properties['vue-route'] = this.$route.name || null
      event.properties['page-url'] = window.location.href || null
      return event
    },
    triggerLoginProcess() {
      const urlParams = new URLSearchParams(window.location.search)
      // remove state param as it need to be generated in the autenticate call
      urlParams.delete('state')
      window.location.href = `/?${urlParams.toString()}`
    },
  },
  computed:{
    mail() {
      return 'mailto:support@apptivegrid.de?subject=ApptiveGrid Support Team&body=Hi, %0D%0A%0D%0A%0D%0AApptiveGrid Login / Registration'
    },
    hasStateParam() {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.has('state')
    },
  }
}
</script>
<style lang="css">
.full-height {
  min-height: 100vh;
}
.login {
  max-width: 380px;
}
.advertisement {
  background-color: #F2F4F7;
}
a {
  font-weight: 600;
  text-decoration: none;
}
.animation {
    animation: fadeInAnimation ease .6s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
</style>
