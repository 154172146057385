<template lang="html">
  <div class="ma-3">
    <h1>{{$t('resetPasswordSuccess.title')}}</h1>
    <div class="mb-6 accent--text">
      {{$t('resetPasswordSuccess.subtitle')}}
    </div>
    <BackToLogin :title="$t('resetPasswordSuccess.buttons.signIn')" />
  </div>
</template>

<script>
import BackToLogin from '@/components/BackToLogin.vue'

export default {
  components: {
    BackToLogin
  }
}
</script>

<style lang="css" scoped>
</style>
