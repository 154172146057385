<template lang="html">
  <div class="login ma-3">
    <h1>{{$t('accoutConfirmation.title')}}</h1>
    <div class="mb-6 accent--text">{{$t('accoutConfirmation.subtitle')}}</div>
    <v-btn @click="toLogin" rounded elevation="2" block large class="button bold mb-5" color="primary" depressed>{{$t('accoutConfirmation.buttons.login')}}</v-btn>

</div>
</template>

<script>
export default {
  methods: {
    toLogin() {
       // to kick off a proper authorise process, we redirect to the app
      const urlParams = new URLSearchParams(window.location.search)
      window.location.href = `${window.location.protocol}//${window.location.host}?${urlParams.toString()}`
    }
  }
}
</script>

<style lang="css" scoped>
</style>
